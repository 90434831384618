'use strict';

import {
    ReactWrapper,
    App
} from "LxComponents";

function NavigationExtension(comp) {
    let weakThis = this;

    if (HD_APP) {
        var platform = PlatformComponent.getPlatformInfoObj().platform;

        if (platform === PlatformType.IOS) {
            StatusBar.hide();
        } else if (platform === PlatformType.Android) {
            StatusBar.showFullScreen();
        }
    } // init rootViewController


    let root = $(document.createElement("root-view"));
    root.css("display", "flex");
    $(document.body).append(root);
    ReactWrapper.render(App, {}, root[0], () => {
        GUI.animationHandler.start();
        //GUI.animationHandler.processSync(true);
        weakThis.rootViewController = App.RootViewController;

        this._animationsEnabled = PersistenceComponent.getAnimationState();
        weakThis.rootViewController.enableAnimations(this._animationsEnabled);

        // http://504F941014D6.dns.loxonecloud.com/#/504F94101436
        // This allows the user to reload the CloudDNS Webinterface

        var url = "#";

        if (window.CLOUD_DNS_MS_ADDRESS) {
            url = "/#";
        } // set initial history entry


        window.history.replaceState({}, "Loxone", url);
        window.history.pushState({}, "Loxone", url);
        weakThis._backNavigationRegistrations = [weakThis._navigateBackOnRootView.bind(weakThis)];

        window.onpopstate = function onpopstate(e) {
            weakThis._handleBackNavigation();

            try {
                window.history.pushState({}, "Loxone", url);
            } catch (e) {
                console.error(e.stack); // after 100 replaceState, iOS crashes, a reload gives another 100! :-)
                //location.reload(); // no longer needed!
            }
        };
        // for iOS swipe back
        window.onNativeNavigateBack = weakThis._handleBackNavigation.bind(weakThis);
        //for Android backbutton
        document.addEventListener("backbutton", weakThis._handleBackNavigation.bind(weakThis), false);
        window.addEventListener("hashchange", function onhashchange(e) {//console.log("hashchange: ", e.newURL, e.oldURL);
        });
        /*window.addEventListener("beforeunload", function onbeforeunload(e) {
            //console.log("beforeunload");
            return "You are about to quit Loxone, are you sure?";
        });*/
    })
}

NavigationExtension.prototype.showState = function showState(state, details, animationType) {
    if (destroyActiveMiniserverPhase(state)) {
        this.activeMiniserverVC = null;
        NavigationComp.setNavigateToLastLocation();
        return AppBranding.resetBranding().finally(function () {

            // Did get stuck with "MissingCreds" on iPad --> attempted to reset to credentials-state, but navigation
            // wasn't ready. Hence now the fix by waiting for the navigationRef to become ready before resetting.
            const doNav = (iteration = 0) => {
                if (App.navigationRef.isReady()) {
                    App.navigationRef.reset(App.DisconnectedState(state, details));
                    return Q.resolve();

                } else if (iteration < 20) {
                    let def = Q.defer();
                    setTimeout(() => {
                        def.resolve(doNav(iteration + 1));
                    }, 50);

                    return def.promise;
                } else {
                    return Q.reject("Failed to show '" + state + "' --> navigation not ready after " + iteration + " retries!")
                }
            }

            return doNav();
            //return this.rootViewController.showState(state, null, details, animationType);
        }.bind(this));
    } else {
        // either we want to show ActiveMiniserverViewController or we have at least to wait for it and let it handle this!
        if (state === ScreenState.ActiveMiniserver) {
            if (!this.activeMiniserverVC) {
                // initialize and save the instance
                this.activeMiniserverVC = App.ActiveMiniserverDummyVC();
                App.navigationRef.reset(App.ActiveMiniserverState());
                return Q.resolve();
            } else {//console.error("WARNING: don't try to show ScreenState.ActiveMiniserver twice!");
            }
        } else if (this.activeMiniserverVC) {
            return this.activeMiniserverVC.showState(state, null, details, animationType);
        }
    }

    if (state === ScreenState.ConnectingWaiting) {
        // RN1-I187 showState in root view controller causes flickering
        return App.navigationRef.navigate(state, details);
    }
    return this.rootViewController.showState(state, null, details, animationType);
};
/**
 * This method will take care to inform the UI that the app will be sent to the background. Otherwise e.g. timers
 * or timeouts that have been started might fire in the background. This way, the lifecycle methods will take care
 * of stopping those.
 */


NavigationExtension.prototype.appPaused = function appPaused() {
    Debug.Navigation && console.log("Informing RootViewController about being paused");
    GUI.animationHandler.processSync(true);
    GUI.animationHandler.flush();
    console.warn("##################################################");
    console.warn("this.rootViewController viewWillDisappear");
    console.warn("##################################################");
    this.pausePromise = Q(this.rootViewController.viewWillDisappear()).then(function () {
        console.warn("##################################################");
        console.warn("this.rootViewController viewWillDisappear passed");
        console.warn("##################################################");
        console.warn("##################################################");
        console.warn("this.rootViewController viewDidDisappear");
        console.warn("##################################################");
        return Q(this.rootViewController.viewDidDisappear()).then(function () {
            console.warn("##################################################");
            console.warn("this.rootViewController viewDidDisappear passed");
            console.warn("##################################################");
        }, null, function () {
            console.warn("##################################################");
            console.warn("Stopped animation frame handler");
            console.warn("##################################################");
            GUI.animationHandler.stop();
        });
    }.bind(this));
};
/**
 * This method will take care to inform the UI that the app will be brought to the foreground again. Timers or
 * registrations that have been canceled when the view was sent to the background, will be reestablished.
 */


NavigationExtension.prototype.appResumed = function appResumed() {
    Debug.Navigation && console.log("Informing RootViewController about being brought back to the front");
    GUI.animationHandler.processSync(false);
    GUI.animationHandler.start();
    var needsRestart = false;

    if (this.pausePromise.inspect().state === "pending") {
        needsRestart = true; // important, the animationHandler is stopped after the pausePromises are done!

        console.warn("##################################################");
        console.warn("Destructive methods aren't completed --> call constructive methods after fulfill");
        console.warn("##################################################"); // install watchdog for pause/resume issues.

        setTimeout(function () {
            if (this.pausePromise && this.pausePromise.inspect().state === "pending") {
                console.error("###################################################");
                console.error("  Pause Promise still pending after 10 seconds!");
                console.error("###################################################");
            }
        }.bind(this), 10 * 1000);
    }

    this.pausePromise.then(function () {
        if (needsRestart) {
            // was stopped again by delayed destructive lifeCycles
            console.warn("##################################################");
            console.warn("         RESTARTING AnimationHandler");
            console.warn("##################################################");
            GUI.animationHandler.start();
        }

        console.warn("##################################################");
        console.warn("this.rootViewController viewWillAppear");
        console.warn("##################################################");
        Q(this.rootViewController.viewWillAppear()).then(function () {
            console.warn("##################################################");
            console.warn("this.rootViewController viewWillAppear passed");
            console.warn("##################################################");
            console.warn("##################################################");
            console.warn("this.rootViewController viewDidAppear");
            console.warn("##################################################");
            return this.rootViewController.viewDidAppear().then(function () {
                console.warn("##################################################");
                console.warn("this.rootViewController viewDidAppear passed");
                console.warn("##################################################");
            }, function (e) {
                console.warn("##################################################");
                console.warn("this.rootViewController viewDidAppear failed");
                console.warn("exception --> " + e);
                console.warn("##################################################");
            });
        }.bind(this), function (e) {
            console.warn("##################################################");
            console.warn("this.rootViewController viewWillAppear failed");
            console.warn("exception --> " + e);
            console.warn("##################################################");
        });
    }.bind(this), function (e) {
        console.error("##################################################");
        console.error("Error in destructive calls (viewWillDisappear and viewDidDisappear) of rootViewController");
        console.error("Failed with exception --> " + e);
        console.error("##################################################");
    }.bind(this));
};
/**
 * navigates back on the root view
 */


NavigationExtension.prototype.navigateBack = function navigateBack() {
    this._navigateBackOnRootView();
};
/**
 * calls the method of the next one in the back navigation queue
 */


NavigationExtension.prototype.navigateBackQueue = function navigateBackQueue() {
    this._handleBackNavigation();
};
/**
 * @see ViewController.getQueueFinishedPromise
 */


NavigationExtension.prototype.getQueueFinishedPromise = function getQueueFinishedPromise() {
    return this.rootViewController.getQueueFinishedPromise();
};
/**
 * The callback provided will be called as soon as back navigation is initiated (e.g. via the browser or android
 * back buttons).
 * @param callback
 * @return {function(this:NavigationExtension)}
 */


NavigationExtension.prototype.registerForBackNavigation = function (callback) {
    if (this._backNavigationRegistrations.indexOf(callback) === -1) {
        this._backNavigationRegistrations.pushObject(callback);
    } else {
        console.warn("back navigation already registered!");
    }

    return this.unregisterFromBackNavigation.bind(this, callback);
};

NavigationExtension.prototype.unregisterFromBackNavigation = function (callback) {
    var idx = this._backNavigationRegistrations.indexOf(callback);

    if (idx !== -1) {
        this._backNavigationRegistrations.splice(idx, 1);
    } else {//console.warn("back navigation not registered!");
    }
};

NavigationExtension.prototype.getURL = function getURL() {
    //console.log("getURL");
    // query the history string from viewManager (and all Sub-ViewManagers)
    return this.rootViewController.getURL();
};

NavigationExtension.prototype.animationsEnabled = function animationsEnabled() {
    return this._animationsEnabled;
};

NavigationExtension.prototype.enableAnimations = function enableAnimations(enabled) {
    this._animationsEnabled = enabled;
    this.rootViewController.enableAnimations(enabled);
};

NavigationExtension.prototype.disableAnimationsTemp = function disableAnimationsTemp() {
    Debug.Navigation && console.log("disableAnimationsTemp");
    this.rootViewController.enableAnimations(false);
    clearTimeout(this._disableAnimationsTimer);
    this._disableAnimationsTimer = setTimeout(function () {
        Debug.Navigation && console.log("set animation back");

        if (typeof this._animationsEnabled === "boolean") {
            Debug.Navigation && console.log("   to variable", this._animationsEnabled);
            this.rootViewController.enableAnimations(this._animationsEnabled);
        } else {
            Debug.Navigation && console.log("   to default", true);
            this.rootViewController.enableAnimations(true);
        }
    }.bind(this), 2000);
}; // Activities


NavigationExtension.prototype.startActivity = function startActivity(activityType, type, details) {
    if (this.activeMiniserverVC) {
        this.activeMiniserverVC.startActivity(activityType, type, details);
    } else {
        console.warn("can't start activity '" + activityType + "' if not in ActiveMiniserver Phase!");
    }
};

NavigationExtension.prototype.stopActivity = function stopActivity(activityType) {
    if (this.activeMiniserverVC) {
        this.activeMiniserverVC.stopActivity(activityType);
    } else {
        console.warn("can't stop activity '" + activityType + "' if not in ActiveMiniserver Phase!");
    }
};

NavigationExtension.prototype.getCurrentView = function getCurrentView() {
    var currentView = this.rootViewController.getCurrentView();

    if (currentView && currentView === this.activeMiniserverVC) {
        return currentView.getCurrentView();
    }

    return currentView;
};

NavigationExtension.prototype._navigateBackOnRootView = function _navigateBackOnRootView() {
    if (this.rootViewController.canNavigateBack() || App.navigationRef.canGoBack()) {
        this.rootViewController.navigateBack();
    } else {
        console.info("INFO: can't navigate back, show the archive!");
        NavigationComp.showArchive();
    }
};

NavigationExtension.prototype._handleBackNavigation = function _handleBackNavigation() {
    //console.log("_handleBackNavigation");
    // always serve the last one in the queue
    try {
        this._backNavigationRegistrations[this._backNavigationRegistrations.length - 1]();
    } catch (e) {
        console.error(e.stack);

        this._backNavigationRegistrations.pop(); // -> remove last one!

    }
};
/**
 * returns true if we show a state which must lead to destroy the active miniserver views!
 * @param state
 * @returns {boolean}
 * @private
 */


var destroyActiveMiniserverPhase = function destroyActiveMiniserverPhase(state) {
    return [
        "Connection",
        "Lobby",
        "ActiveMiniserver",
        ScreenState.InitialSearch,
        ScreenState.Welcome,
        ScreenState.Archive,
        ScreenState.MiniserverSearch,
        ScreenState.EnterUrl,
        ScreenState.Credentials,
        ScreenState.ConnectingWaiting,
        ScreenState.Error
    ].includes(state)
};

export default NavigationExtension;

import {
    App
} from "LxComponents";
import UrlHandlerExt from "./UrlHandlerExt";
import NavigationExt from "./NavigationExt";
import AmbientUtils from "../react-comps/AmbientMode/AmbientUtils";
import {getRouteFromNavigationState} from "../react-comps/navigation/LxReactNavigationStateAnalyzer";
import AmbientStackNavigator from "../react-comps/AmbientMode/AmbientStackNavigator";
import {CommonActions} from "@react-navigation/native";
import AmbientScreen from "../react-comps/AmbientMode/screens/AmbientScreen";
import {
    getNavigationState
} from "../react-comps/navigation/LxReactNavigationStateAnalyzer";

window.Components = function (Components) {
    function NavigationComponent() {
        // Override the default behaviour of all a tags when clicked (Clicking on a link to use our default openWebsite function)
        document.onclick = function (e) {
            e = e || window.event;
            var element = e.target || e.srcElement;

            if (element && element.tagName === 'A' && element.href) {
                NavigationComp.openWebsite(element.href);
                return false; // prevent default action and stop event propagation
            }
        }; // initializes this component as observer


        Observer.call(this); // extensions

        this.NavigationExt = initExtension(NavigationExt, this);
        this.UrlHandlerExt = initExtension(UrlHandlerExt, this);
        this._waitingForConnection = true;
        this._navigateToLastPosition = true;
        this._locationQueue = []; // handling to prevent duplicate control alerts/contents

        this._navigateToControlMap = {}; // the promise from the first call will be added to this map, all upcoming calls will be resolved with this promise. When the promise is resolved, it will be removed from the map.

        this._currentReachMode = ReachMode.NONE; // Navigate to the last URL if the reachability changed and the connection has already been established

        CompChannel.on(CCEvent.ConnEstablished, function (event, ms, reachMode, url) {
            // Why do we check for ReachMode.NONE
            // This only needs to be performed when the app has already been opened, so we have had a reachMode already
            if (this._currentReachMode !== ReachMode.NONE && this._currentReachMode !== reachMode && !this._waitingForConnection) {
                // Don't override the delayed URL, it may be set via an URL Start or Push Notification
                if (!this._delayedHandleURL) {
                    this._delayedHandleURL = this.UrlHandlerExt.getLastURL();
                }
            }

            this._currentReachMode = reachMode;
        }.bind(this)); // Listen for this event to set this._waitingForConnection, this is crucial for the URL start to be executed
        // at the right time while the reachmode changes local <==> remote.
        // Without this the location will be opened in the current reachmode and after the reachmode has changed the
        // entry point location will be opened

        CompChannel.on(CCEvent.REACHABILITY_UPDATE, function (event, reachMode) {
            this._waitingForConnection = reachMode === ReachMode.NONE;
            this.dispatchEventToUI(NavigationComp.UiEvents.ReachabilityUpdate, reachMode);
        }.bind(this));
        CompChannel.on(CCEvent.StructureReady, function (event, args) {
            // Show the QuickActionMenuAcceptScreen only once and only if LoxoneControl is available
            // 1. Check if the platform has LoxoneControl (macOS)
            // 2. Check if the flag already exists (User has already installed a previous version of the App)
            // 3. Check if the user hasn't already accepted the QuickActionMenuAcceptScreen
            if (LoxoneControl.hasLoxoneControl() && !LoxoneControl.isStartingWithSystem() && PersistenceComponent.shouldShowQuickActionMenuAcceptScreen()) {
                this.showQuickActionMenuAcceptScreen();
            } // show again the default screen to ensure that the ActiveMiniserverVC is shown
            // try: connect to MS, lock iPad, save into MS, wait, unlock iPad


            this._showState(ScreenState.ActiveMiniserver); // use showState here to skip the queue
            // check if someone tried to handle a URL while connection isn't established..


            if (this._delayedHandleURL) {
                // make sure, that all currently running animations/transitions are "over", before navigating to the last position!
                // otherwise, it might be, that eg. the StructureReady event is faster than the WaitingScreen is actually shown "fully"
                // (reproduce: WIFI, config with only 1 object, navigate to that ControlContent, lock phone, save into MS, unlock phone -> white screen)
                // in that case, the last position might still be for example the ControlContent, and we would then stop navigating back to that, because it seems like we already display the correct Screen
                // short: the connection and structure-ready is faster than the WaitingScreen in-animation, which leads to that white screen
                this.NavigationExt.getQueueFinishedPromise().done(function () {
                    Debug.GUI.NavigationComp && console.info(this.name, " - now handleURL with _delayedHAndleURL");
                    this.handleURL(this._delayedHandleURL);
                    this._delayedHandleURL = null;
                }.bind(this));
            } else if (this._navigateToLastPosition && this._locationQueue.length === 0) {
                Debug.GUI.NavigationComp && console.info(this.name, " - asking UrlHandler for last location..");
                var lastURL = this.UrlHandlerExt.getEntryPointURL(); // make sure, that all currently running animations/transitions are "over", before navigating to the last position!
                // otherwise, it might be, that eg. the StructureReady event is faster than the WaitingScreen is actually shown "fully"
                // (reproduce: WIFI, config with only 1 object, navigate to that ControlContent, lock phone, save into MS, unlock phone -> white screen)
                // in that case, the last position might still be for example the ControlContent, and we would then stop navigating back to that, because it seems like we already display the correct Screen
                // short: the connection and structure-ready is faster than the WaitingScreen in-animation, which leads to that white screen

                this.NavigationExt.getQueueFinishedPromise().done(function () {
                    Debug.GUI.NavigationComp && console.info(this.name, " - now handleURL with lastLocation or entryPoint");

                    if (AmbientUtils.shouldStartWithAmbient(EntryPointHelper.getLocation())) {
                        console.log(this.name, " - ambient mode should take over (url would be: " + lastURL + ")");
                        this.showState(ScreenState.AmbientScreen);

                    } else if (EntryPointHelper.getLocation() === UrlStartLocation.LAST_POSITION) {
                        this.handleURL(lastURL); // even if we don't get an URL, we show the HomeScreen!
                    } else {
                        // directly jump to the entry point location
                        this.showEntryPointLocation();
                    }
                }.bind(this));
            }

            this._navigateToLastPosition = false; // set here to false because it's value is needed above

            this._processActiveMiniserverStateQueue();

        }.bind(this));
        CompChannel.on(CCEvent.StopMSSession, function (ev, resetUrl) {
            Debug.GUI.NavigationComp && console.info(this.name, "StopMSSession");
            this._waitingForConnection = true;
            this._navigateToLastPosition = true;

            if (resetUrl) {
                this.UrlHandlerExt.resetLastURL();
            }

            Debug.EcoScreen && console.log(this.name, "StopMSSession - trigger activity tick, then unregister");
            SandboxComponent.activityTick()
        }.bind(this));
        CompChannel.on(CCEvent.DownloadingLargeData, function (event, data) {//GUI.DownloadProgressBar.updateData(data); --> no longer do that. it's annoying to the user & the data is estimated.
        });
        CompChannel.on(CCEvent.Resume, this.NavigationExt.appResumed.bind(this.NavigationExt));
        CompChannel.on(CCEvent.Pause, this.NavigationExt.appPaused.bind(this.NavigationExt)); // accessibility

        this._updateTextSizeAdjustment();

        window.onFontSizeAdjustmentDidChange = function (textSizeAdjustment) {
            PlatformComponent.getPlatformInfoObj().accessibility.textSizeAdjustment = textSizeAdjustment;

            this._updateTextSizeAdjustment();
        }.bind(this); // dark mode


        this.darkModeActive = PersistenceComponent.getDarkModeState(); // For automatic Dark- LightMode switch

        /*var isDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches;
        window.matchMedia("(prefers-color-scheme: dark)").addListener(function(e) {
            if (e.matches) {
                this.setDarkModeState(true, true);
            } else {
                this.setDarkModeState(false, true);
            }
        }.bind(this));
        this.setDarkModeState(isDarkMode, true);*/

        this.setDarkModeState(
            /*isDarkMode*/
            true, true); // Popups

        this.popupManager = new GUI.PopupManager(); // Notifications

        this.notificationManager = new GUI.NotificationManager();
        this.name = "NavigationComp";
    } // Public methods


    /**
     * Method which sets the current dark mode state
     * @param isActive flag for the active state
     * @param doNotSaveState true if the state should not be saved
     */


    NavigationComponent.prototype.setDarkModeState = function setDarkModeState(isActive, doNotSaveState) {
        var body = $(document.body);
        body.toggleClass('light-mode', !isActive);
        body.toggleClass('dark-mode', !!isActive);
        setColorObject(isActive);

        if (!doNotSaveState) {
            this.darkModeActive = isActive;
            PersistenceComponent.setDarkModeState(this.darkModeActive); // Emit an ForceStateUpdate Event, so e.g. jalousie or gate controls are properly visualized

            CompChannel.emit(CCEvent.ForceStateUpdate);
        }
    };
    /**
     * Returns a flag, if the dark mode is currently active
     * @returns {Boolean}
     */


    NavigationComponent.prototype.getDarkModeState = function getDarkModeState() {
        return this.darkModeActive;
    };

    NavigationComponent.prototype.getStatusBarHeight = function getStatusBarHeight() {
        var platformObject = PlatformComponent.getPlatformInfoObj();

        if (platformObject.platform === PlatformType.IOS && parseInt(platformObject.version) > 0) {
            // TODO look why we check the version also here? does this make sense?
            if (HD_APP) {
                return 0;
            } else if (PlatformComponent.isIphoneX()) {
                return this.getSafeAreas().top;
            } else {
                return 20;
            }
        }

        return 0;
    };
    /**
     * Returns the safe ares of a device. This function has been introduced to support the iPhone X notch and home bar.
     * Safe Areas basically define the areas with no user interaction because of system software controls.
     * @returns {*} top, bottom, left and right
     */


    NavigationComponent.prototype.getSafeAreas = function getSafeAreas() {
        var safeAreas = {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
        };

        if (PlatformComponent.isIphoneX()) {
            // Attention:
            // iOS 11.1 and down doesn't support env() but constant()
            // iOS 11.2 and up doesn't support constant() but env()
            // Always use the bottom safe area to check if this is an iPhoneX. The top safeArea may not exist on WebClips
            var div = document.createElement('div'),
                hasEnv = CSS.supports('padding-bottom: env(safe-area-inset-bottom)'),
                prefix = hasEnv ? "env" : "constant";
            Object.keys(safeAreas).forEach(function (safeKey) {
                var cssProperty = "padding" + safeKey.capitalize();
                div.style[cssProperty] = prefix + '(safe-area-inset-' + safeKey + ')';
            });
            document.body.appendChild(div);
            Object.keys(safeAreas).forEach(function (safeKey) {
                var cssProperty = "padding" + safeKey.capitalize();
                safeAreas[safeKey] = parseInt(window.getComputedStyle(div)[cssProperty], 10);
            });
            document.body.removeChild(div);
            return safeAreas;
        }

        return safeAreas;
    };

    NavigationComponent.prototype.getTitleBarInformation = function getTitleBarInformation() {
        var platform = PlatformComponent.getPlatformInfoObj().platform,
            statusBarHeight = this.getStatusBarHeight(),
            titleBarHeight = statusBarHeight + 60;

        if (HD_APP) {
            titleBarHeight += 10;
        }

        if (platform === PlatformType.Mac) {
            titleBarHeight += 20;
        }

        return {
            height: titleBarHeight,
            osStatusBarHeight: statusBarHeight
        };
    };

    NavigationComponent.prototype.setHomeScreenAvailability = function setHomeScreenAvailability(isAvailable) {
        this.homeScreenIsAvailable = isAvailable; // emit event

        this.dispatchEventToUI(NavigationComp.UiEvents.HomeScreenAvailability, isAvailable);
        PersistenceComponent.activateHomeScreen(this.homeScreenIsAvailable);
    };

    NavigationComponent.prototype.getHomeScreenAvailability = function getHomeScreenAvailability() {
        return this.homeScreenIsAvailable;
    }; //TODO-woessto: most of these events don't affect the navigation component (e.g. Favorites). The PersistenceComp
    // with it's settingsExt would be the best source of those events. If NavigationComp needs events, it can register
    // for those on the CompChannel.


    NavigationComponent.prototype.UiEvents = {
        HomeScreenAvailability: "HomeScreenAvailabilityChanged",
        SortByRatingChanged: "SortByRatingChanged",
        FavoritesChanged: "FavoritesChanged",
        SortingStructureChanged: "SortingStructureChanged",
        //ExpertModeChanged: "ExpertModeChanged",             // enabled
        StructureChanged: "StructureChanged",
        SimpleDesignChanged: "SimpleDesignChanged",
        // enabled
        UrlUpdate: "UrlUpdate",
        // enabled
        TilePresentationChanged: "TilePresentationChanged",
        ReachabilityUpdate: "ReachabiltyUpdate"
    };

    NavigationComponent.prototype.dispatchEventToUI = function dispatchEventToUI(eventId, payload) {
        this.emit(eventId, payload);
    };

    NavigationComponent.prototype.registerForUIEvent = function registerForUIEvent(eventId, fn) {
        return this.on(eventId, fn);
    };

    NavigationComponent.prototype.removeFromUIEvent = function removeFromUIEvent(unregisterFn) {
        this.off(unregisterFn);
    };

    NavigationComponent.prototype.showState = function showState(state, details, animationType) {
        console.info(this.name, "NavigationComponent showState:", state);
        developerAttention(
            'Navigating using "NavigationComponent" is highly discouraged! Use the "navigation" object whenever possible (new navigation)!',
            "hotpink"
        );
        return this._showState.apply(this, arguments);
    };
    /**
     * @see NavigationExt.navigateBack
     */


    NavigationComponent.prototype.navigateBack = function () {
        developerAttention(
            'Navigating back using "NavigationComponent" is highly discouraged! Use the "this.ViewController.navigateBack" or even better "navigation.goBack" whenever possible (new navigation)!',
            "hotpink"
        );
        return this.NavigationExt.navigateBack.apply(this.NavigationExt, arguments);
    };
    /**
     * @see NavigationExt.navigateBackQueue
     */


    NavigationComponent.prototype.navigateBackQueue = function () {
        return this.NavigationExt.navigateBackQueue.apply(this.NavigationExt, arguments);
    };

    NavigationComponent.prototype.registerForBackNavigation = function () {
        return this.NavigationExt.registerForBackNavigation.apply(this.NavigationExt, arguments);
    };

    NavigationComponent.prototype.unregisterFromBackNavigation = function () {
        return this.NavigationExt.unregisterFromBackNavigation.apply(this.NavigationExt, arguments);
    };

    NavigationComponent.prototype.showBackupRestore = function showBackupRestore() {
        return this.showState(ScreenState.BackupAndSyncSetupViewController, {
            type: BackupAndSync.ADD
        }, AnimationType.MODAL);
    };
    /**
     * connects to the demo miniserver
     */


    NavigationComponent.prototype.connectToDemoMiniserver = function connectToDemoMiniserver() {
        this.connectTo(CommunicationComponent.getDemoMiniserver());
    };

    NavigationComponent.prototype.connectToLastMiniserver = function connectToLastMiniserver() {
        if (ActiveMSComponent.getActiveMiniserver() !== null) {// we are connected to a Miniserver
        } else {
            this.connectTo(PersistenceComponent.getLastConnectedMiniserver());
        }
    };
    /**
     * Emits a broadcast to connect to the miniserver
     */


    NavigationComponent.prototype.connectTo = function connectTo(ms, keepPrevMsInfo = false) {
        if (PairedAppComponent.isPaired() && !PairedAppComponent.isPairedMiniserver(ms)) {
            console.error("NavigationComponent", "trying to switch to a different miniserver, while paired!");
            return this.showArchive();
        }


        let currentView = this.getCurrentView();

        this._cleanActiveMiniserverStateQueue(); // Dismiss all ModalViewControllers, as we can't show a full screen view over a ModalViewController

        this._waitingForConnection = true; // indicates that we want to connect to a Miniserver, but we don't have a connection established yet!

        this._navigateToLastPosition = true; // Dismiss all ModalViewControllers, as we can't show a full screen view over a ModalViewController

        if (currentView && currentView.ViewController && currentView.ViewController instanceof GUI.ModalViewController) {
            return currentView.ViewController.dismiss().then(() => {
                return this.connectTo.apply(this, arguments);
            });
        } else {
            // check if we should reset url
            var resetUrl = ActiveMSComponent.getActiveMiniserver() != null;
            Debug.MSSession && console.log(this.name, "connectTo -> StopMSSession first");
            CompChannel.emit(CCEvent.StopMSSession, resetUrl); // check if miniserver has already a serial number and lookup settings for stored information

            if (ms.serialNo && PersistenceComponent.getMiniserverSettings(ms.serialNo)) {
                var homeScreenSettings = PersistenceComponent.getMiniserverSettings(ms.serialNo).homeScreen;
                this.homeScreenIsAvailable = homeScreenSettings.activated;
            } else {
                this.homeScreenIsAvailable = PersistenceComponent.getDefaultSettings().Miniserver.HomeScreen.ACTIVATED;
            }

            if (!keepPrevMsInfo) {
                this.resetPreviousMsInfo();
            }

            CompChannel.emit(CCEvent.StartMSSession, ms);
        }
    };

    /**
     * Like a connect to, but this ensures a "Switch back to previous-ms"- Banner is visible when connected to the new one.
     * @param ms
     * @returns {*}
     */
    NavigationComponent.prototype.switchToMiniserver = function switchToMiniserver(ms) {
        (Debug.Control.MsShortcut || Debug.Trust)  && console.log(this.name, "switchToMiniserver: " + (ms.msName || ms.serialNo || ms.localUrl || ms.remoteUrl));
        const activeMsSerial = ActiveMSComponent.getActiveMiniserverSerial(),
            activeMsName = ActiveMSComponent.getActiveMiniserver().msName;

        this.setPreviousMsInfo(activeMsSerial, activeMsName)

        return this.connectTo(ms, true);
    };

    NavigationComponent.prototype.setPreviousMsInfo = function setPreviousMsInfo(serialNo, msName) {
        (Debug.Control.MsShortcut || Debug.Trust) && console.log(this.name, "setPreviousMsInfo: " + msName + ", snr=" + serialNo);
        this._previousMsInfo = {serialNo, msName};
    }
    NavigationComponent.prototype.resetPreviousMsInfo = function resetPreviousMsInfo() {
        (Debug.Control.MsShortcut || Debug.Trust)  && console.log(this.name, "resetPreviousMsInfo");
        this._previousMsInfo = null;
    }
    NavigationComponent.prototype.getPreviousMsInfo = function getPreviousMsInfo() {
        (Debug.Control.MsShortcut || Debug.Trust)  && console.log(this.name, "getPreviousMsInfo: " + JSON.stringify(this._previousMsInfo));
        return this._previousMsInfo;
    }

    /**
     * opens either the native browser app or opens a new tab. Link has to open after 1500ms (app goes to bg in the meantime)
     * otherwise the returned promise rejects.
     * Is also used to open up third party apps by opening links like "sms://" or "casatunes://"
     * @param link  the link to open
     * @param [oDef] maybe there is a deferred already (used to retry opening links)
     * @param [failFn] optional method to call when opening the website failed. E.g. used when opening a third party app.
     * @returns {*} a promise that resolves once the link was opened or rejects if it was blocked
     */


    NavigationComponent.prototype.openWebsite = function openWebsite(link, oDef, failFn) {
        var def = oDef ? oDef : Q.defer(),
            visChanged = function () {
                document.hidden && onOpen();
            },
            onOpen = function () {
                clearTimeout(this._openWsCheck);
                this._openWsCheck = null;
                document.removeEventListener("visibilitychange", visChanged);
                def.resolve();
            }.bind(this),
            onFail = function (e) {
                console.warn("Couldn't open website");
                console.error(e);
                clearTimeout(this._openWsCheck);
                this._openWsCheck = null;
                document.removeEventListener("visibilitychange", visChanged); // either call a fail fn that was passed in, or an external failFn.

                failFn ? failFn() : this._handleOpenLinkFailed(link, def);
            }.bind(this);

        document.addEventListener("visibilitychange", visChanged);
        this._openWsCheck = setTimeout(onFail, 1500);

        if (PlatformComponent.getPlatformInfoObj().platform === PlatformType.Webinterface || PlatformComponent.getPlatformInfoObj().platform === PlatformType.DeveloperInterface) {
            window.open(link, "_blank");
        } else {
            appLink.openAppWithUrl(onOpen, onFail, link);
        } // add empty handlers to avoid error logs if caller doesn't handle them.


        def.promise.then(function () {
        }, function () {
        });
        return def.promise;
    };
    /**
     * Called whenever opening the link fails. Informs the user that popup blockers might avoid opening
     * a link and will ask to try again.
     * @private
     */


    NavigationComponent.prototype._handleOpenLinkFailed = function _handleOpenLinkFailed(link, def) {
        var content = {
            title: _("openlink.failed.title"),
            message: _("openlink.failed.message", {
                link: link
            }),
            buttonOk: _("openlink.failed.retry"),
            buttonCancel: true
        };
        NavigationComp.showPopup(content).then(this.openWebsite.bind(this, link, def, null), // Null cause otherwise "ok" will be the failFn of openWebsite
            def.reject.bind(def));
    };
    /*
     Concrete View-Methods
     */


    NavigationComponent.prototype.showWelcomeScreen = function showWelcomeScreen() {
        this._showState(ScreenState.Welcome);
    };

    NavigationComponent.prototype.showArchive = function showArchive() {
        var def = Q.defer(); // The Webinterface doesn't have any archive, just reload the page to navigate to the preload login

        if (PlatformComponent.getPlatformInfoObj().platform === PlatformType.Webinterface) {
            def.resolve();
            location.reload();
        } else {
            this._disconnectFromMsIfNeeded();
            def.resolve(this._showState(ScreenState.Archive));
        }

        return def.promise;
    };

    NavigationComponent.prototype.showEnterUrlScreen = function showEnterUrlScreen() {
        this._showState(ScreenState.EnterUrl);

        this._disconnectFromMsIfNeeded();
    };

    NavigationComponent.prototype.showWaitingView = function showWaitingView(details) {
        let state = App.navigationRef.getState();
        if (state && state.routes.find(route => route.name === ScreenState.ConnectingWaiting)) {
            // Ro properly update the screen we simply need to navigate to it and not reset the navigation stack
            App.navigationRef.navigate(ScreenState.ConnectingWaiting, details);
        } else {
            this._showState(ScreenState.ConnectingWaiting, details);
        }
    };
    /**
     * Will present a waiting view if the promise does neither resolve nor reject within a specific timeout. If needed
     * the user can also press cancel in the waiting popup.
     * @param promise               the promise that is being waited for.
     * @param [title]               the title to present in the waiting view (please wait if not defined)
     * @param [message]             the message shown in the waiting view (please wait if a title is defined, not used if no title is defined)
     * @param [cancel]              if true or a string, the waiting popup contains a cancel button.
     * @param [boundRetryFunction]  the bound function to be executed if the defined promise fails
     * @param [retryMessage]        the message for the retry popup
     */


    NavigationComponent.prototype.showWaitingFor = function showWaitingFor(promise, title, message, cancel, boundRetryFunction, retryMessage) {
        var showPopupTimeout,
            waitingPopup,
            cntntTitle = title ? title : _("please-wait"),
            cntntMsg = message ? message : title ? _("please-wait") : null,
            waitingContent = {
                title: cntntTitle,
                message: cntntMsg,
                buttonCancel: cancel,
                icon: Icon.INDICATOR_BOLD
            },
            def = Q.defer(),
            retryFunction = typeof boundRetryFunction === "function" ? boundRetryFunction() : null,
            waitingRegisterPromise = getStack(1); // start a timeout that will present a waiting popup.

        showPopupTimeout = setTimeout(function () {
            // if the timeout fires, a waiting popup is needed. It will never fire if the promise has already been rejected
            // or resolved.
            Debug.GUI.WaitingPopup && console.log(this.name, "showWaitingPopup - timer fired, show " + cntntTitle + " - " + cntntMsg);
            Debug.GUI.WaitingPopup && console.log(this.name, "      showWaitingFor prms origin: " + promise.stack);
            Debug.GUI.WaitingPopup && console.log(this.name, "      showWaitingFor scheduled from: " + JSON.stringify(waitingRegisterPromise));
            waitingPopup = NavigationComp.showPopup(waitingContent); // don't attach the .then to the showPopup, otherwise the popup cannot be dismissed as it's a different promise.

            waitingPopup.then(null, function (buttonType) {
                if (buttonType === GUI.PopupBase.ButtonType.CANCEL && cancel) {
                    def && def.reject(buttonType);
                    def = null;
                }
            });
            def && def.notify(waitingPopup.popup); // notify with the instance of the popup, to give the caller the possibility to update some texts..
        }.bind(this), WAITING_INFO_TIMEOUT); // Helper function to call when the promise has been resolved or rejected

        var _promiseResponded = function _promiseResponded() {
            // By wrapping it in a timeout with 0 ms we make it async and use an own "thread"
            // this will fix the timing problem
            setTimeout(function () {
                showPopupTimeout && clearTimeout(showPopupTimeout);
                showPopupTimeout = null;

                if (waitingPopup) {
                    Debug.GUI.WaitingPopup && console.log(this.name, "showWaitingPopup - removing " + cntntTitle + " - " + cntntMsg);
                    Debug.GUI.WaitingPopup && console.log(this.name, " showWaitingFor prms origin: " + promise.stack);
                }

                waitingPopup && NavigationComp.removePopup(waitingPopup);
                waitingPopup = null;
            }, 0);
        }; // don't return the then-promise - as it is allowed for the user to cancel it via the popup too.


        promise.then(function (result) {
            def && def.resolve(result);
            def = null;

            _promiseResponded();
        }.bind(this), function (e) {
            // Don't show the retry popup if the user canceled a popup (eg. authentication popup)
            if (retryFunction && e !== "cancel") {
                // We need to show the retry popup, hide the current waiting popup with promiseResponded()
                _promiseResponded();

                return NavigationComp.showRetryPopUp(promise, title, message, cancel, boundRetryFunction, retryMessage).then(function (result) {
                    def && def.resolve(result);
                    def = null;
                }, function (err) {
                    console.error(err);
                    def && def.reject(err);
                    def = null;
                });
            } else {
                def && def.reject(e);
                def = null;

                _promiseResponded();
            }
        });
        return def.promise;
    };
    /**
     * Will present a retry view
     * @param promise               the promise that is being waited for.
     * @param [title]               the title to present in the waiting view (please wait if not defined)
     * @param [message]             the message shown in the waiting view (please wait if a title is defined, not used if no title is defined)
     * @param [cancel]              if true or a string, the waiting popup contains a cancel button.
     * @param [boundRetryFunction]  the bound function to be executed if the defined promise fails
     * @param [retryMessage]        the message for the retry popup
     */


    NavigationComponent.prototype.showRetryPopUp = function showRetryPopUp(promise, title, message, cancel, boundRetryFunction, retryMessage) {
        var retryFunction = boundRetryFunction(),
            retryMsg = retryMessage ? retryMessage : _("error.view-cant-be-loaded"),
            retryContent = {
                title: _("error"),
                message: retryMsg,
                buttonCancel: true,
                buttonOk: _("try-again"),
                color: window.Styles.colors.orange
            };
        return NavigationComp.showPopup(retryContent).then(function () {
            return NavigationComp.showWaitingFor(Q(retryFunction()), title, message, cancel, boundRetryFunction, retryMessage);
        }.bind(this));
    };

    NavigationComponent.prototype.showCopyPopup = function showCopyPopup(title, value) {
        return NavigationComp.showPopup({
            title: title,
            message: value,
            buttonOk: _('ok.short'),
            // Copy function is not working on Safari
            buttonCancel: _('copy'),
            color: window.Styles.colors.stateActive
        }).then(function (res) {// We don't have to do anything
        }.bind(this), function (err) {
            copyToClipboard(value);
            HapticFeedback();
        }.bind(this));
    };

    NavigationComponent.prototype.requestDebuglog = function requestDebuglog(id, title, msg, buttonText, cancelButtonText, submitAction, cancelAction) {
        if (GUI.DebugScreen?.enabled) {
            console.warn("=======================");
            console.warn("Debuglog with ID: " + id);
            console.warn("=======================");
            return NavigationComp.showPopup({
                title: title || "Debuglog wanted",
                message: msg || "An issue occurred, please send the debuglog to issue@loxone.com and notify us about this in Teams",
                buttonOk: buttonText || "Show Debuglog",
                buttonCancel: cancelButtonText || false,
                color: window.Styles.colors.red
            }).then(function (res) {
                if (submitAction) {
                    submitAction();
                } else {
                    GUI.DebugScreen.show();
                }
            }.bind(this), function (err) {
                if (cancelAction) {
                    cancelAction();
                }
            });
        }
    };

    NavigationComponent.prototype.showAddMiniserverContextMenu = function showAddMiniserverContextMenu(screen) {
        var options = [],
            canSearch = PlatformComponent.getNetworkStatus().status === NetworkStatus.LAN && MiniserverFinder.hasMSFinder(),
            title = _("miniserverlist.add"),
            platform = PlatformComponent.getPlatformInfoObj().platform;

        canSearch && options.push({
            title: _("miniserverlist.add.search"),
            action: this.showMiniserverSearchScreen.bind(this)
        });
        options.push({
            title: _("miniserverlist.add.manual"),
            action: this.showEnterUrlScreen.bind(this)
        });
        options.push({
            title: _("miniserverlist.add.test"),
            action: this.connectToDemoMiniserver.bind(this)
        });

        if ((window.hasOwnProperty("syncPlugin") || platform === PlatformType.DeveloperInterface) && !Object.keys(PersistenceComponent.getAllMiniserver()).length) {
            options.push({
                title: _("backup-and-sync.archive.cells.restore.title"),
                action: this.showBackupRestore.bind(this)
            });
        }

        screen._showContextMenu(options, title); // don't pass the origin along, show it as centered ctx-menu on HD

    };

    NavigationComponent.prototype.showContextMenu = function showContextMenu(screen, options, title, element, ctor) {
        var ctxMenuHandler = this._findContextMenuHandler(screen),
            handled = false;

        if (ctxMenuHandler) {
            handled = true;

            ctxMenuHandler._showContextMenu(options, title, element, ctor);
        } else {
            console.error("Couldn't find a context menu handler in this screens viewController hierarchy!");
        }

        return handled;
    };

    /**
     * Temporary adoption that converts context menu options into a popup to be shown.
     * @param [screen]  unused
     * @param options   required
     * @param title     required
     * @param [element] unused
     * @param [ctor]    unused
     * @returns {*}
     */
    NavigationComponent.prototype.showContextMenuTemp = function showContextMenuTemp(screen, options, title, element, ctor) {
        var popupContent = {
            title: title,
            buttons: options.map((btnObj) => {
                return {title: btnObj.title};
            })
        };

        return this.showPopup(popupContent).then(function (idx) {
            return options[idx].action();
        }, function () {
            return Q.resolve();
        });
    };

    NavigationComponent.prototype._findContextMenuHandler = function _findContextMenuHandler(screen) {
        var contextMenuHandler = null;

        if (screen._showContextMenu !== undefined) {
            contextMenuHandler = screen;
        } else if (screen.ViewController) {
            contextMenuHandler = this._findContextMenuHandler(screen.ViewController);
        }

        return contextMenuHandler;
    };

    NavigationComponent.prototype.showErrorView = function showErrorView(details) {
        this._showState(ScreenState.Error, details); // TODO remove this to somewhere else! has nothing to to with navigation!


        if (details.errorCode !== ErrorCode.MsSearchNotFound) {
            Debug.MSSession && console.log(this.name, "showErrorView -> StopMSSession (errorCode: " + details.errorCode + ")");
            CompChannel.emit(CCEvent.StopMSSession, true);
        }
    };

    NavigationComponent.prototype.showCredentialsView = function showCredentialsView(details) {
        if (PlatformComponent.getPlatformInfoObj().platform === PlatformType.Webinterface) {
            location.reload();
        } else {
            if (details?.state !== LoginState.MISSING_CREDS) {
                CompChannel.emit(CCEvent.Unauthorized, details);
            }
            this._showState(ScreenState.Credentials, details);
        }
    };

    NavigationComponent.prototype.showMiniserverSearchScreen = function showMiniserverSearchScreen(details) {
        this._showState(ScreenState.MiniserverSearch, details);
    };
    /**
     * Will present the inApp search
     * @param keyword   optional, default: empty. will start a search for this keyword right away
     * @param location  optional, defautl: all, the user could specify that the search should only be about e.g. controls or rooms
     */
    NavigationComponent.prototype.showSearchScreen = function showSearchScreen(keyword, location) {
        this._showState(ScreenState.SearchScreen, {
            keyword: keyword,
            location: location
        });
    };

    NavigationComponent.prototype.showStatistic = function showStatistic(controlUUID, outputUUID) {
        return this._showActiveMiniserverState(ScreenState.Statistic, {
            controlUUID: controlUUID,
            statisticOutputUUID: outputUUID
        });
    };

    NavigationComponent.prototype.showGroupContent = function showGroupContent(groupType, groupUUID, scrollToUUID, fromEntryPoint) {
        Debug.GUI.NavigationComp && console.log(this.name, "showGroupContent:", groupType, groupUUID, scrollToUUID);

        if (this.getURL().indexOf(groupType + "/" + groupUUID) !== -1) {
            Debug.GUI.NavigationComp && console.log(this.name, " - we already show this group content, do nothing!");
            return Q.fcall(function () {
                return "already showing control!";
            });
        }

        if (!groupUUID || !ActiveMSComponent.getStructureManager().getGroupByUUID(groupUUID)) {
            return Q.fcall(function () {
                return "group not found!";
            });
        }

        let detailScreenState = groupType === GroupTypes.CATEGORY ? ScreenState.CategoryDetail : ScreenState.RoomDetail;

        let groupScreenState = groupType === GroupTypes.CATEGORY ? ScreenState.Categories : ScreenState.Rooms;

        let detailScreenDetails = {
            screen: detailScreenState,
            params: {
                groupType: groupType,
                groupUUID: groupUUID,
                scrollToUUID: scrollToUUID
            }
        }
        if (AMBIENT_MODE) {
            App.navigationRef.navigate(ScreenState.AmbientScreen, {
                screen: AmbientStackNavigator.name,
                params: {
                    screen: ScreenState.ActiveMiniserverScreen,
                    params: {
                        screen: groupScreenState,
                        params: detailScreenDetails
                    }
                }
            })
        } else {
            App.navigationRef.navigate(groupScreenState, detailScreenDetails);
        }

        return Q.resolve();
    };
    /**
     * navigates to the entry point location
     * @param skipIfLastPosition used to differentiate between the first launch and eg. an App Pause
     */


    NavigationComponent.prototype.showEntryPointLocation = function showEntryPointLocation(skipIfLastPosition) {
        var def = Q.defer();

        if (skipIfLastPosition && EntryPointHelper.getLocation() === UrlStartLocation.LAST_POSITION) {
            def.resolve();
        } else {
            if (EntryPointHelper.getLocation() === UrlStartLocation.LIKE_PRESENCE_DETECTION) {
                this.getLikePresenceDetectionRoomLocation().then((urlLoc) => {
                    def.resolve(UrlHelper.apply(EntryPointHelper.getLocationUrl(urlLoc)) || true);
                })
            } else {
                def.resolve(UrlHelper.apply(EntryPointHelper.getLocationUrl()) || true);
            }
        }

        return def.promise;
    };

    NavigationComponent.prototype.getLikePresenceDetectionRoomLocation = function getLikePresenceDetectionRoomLocation () {
        if (PlatformComponent.isDeveloperInterface()) {
            // for debugging purposes use the first & best rated room with a presence detector in it
            const structMngr = ActiveMSComponent.getStructureManager();
            let rooms = structMngr.getGroupsByType(GroupTypes.ROOM);
            rooms = rooms.filter(room => {
                let pCtrls = structMngr.getControlsInGroup(GroupTypes.ROOM, room.uuid,true).filter(ctrl => {
                    return ctrl.controlType === ControlType.PRESENCE_DETECTOR
                });
                return room.type === RoomType.COMMON_ROOM && pCtrls.length > 0;
            })
            if (rooms.length > 0) {
                return Q.resolve(UrlStartLocation.ROOM + "/" + rooms[0].uuid);
            }
            // if not just jump to the rooms tab, to make sth visible.
            return Q.resolve(UrlStartLocation.ROOM);
        }
        return LoxoneControl.getPresenceRoomUuid().then( (roomUuid) => {
            var urlLoc = UrlStartLocation.FAVORITES;
            if (roomUuid && typeof roomUuid === "string") {
                urlLoc = UrlStartLocation.ROOM + "/" + roomUuid;
            }
            return urlLoc;
        }, (err) => {
            return UrlStartLocation.FAVORITES;
        });
    }

    NavigationComponent.prototype.getCurrentLocationUrl = function getCurrentLocationUrl() {
        return this.UrlHandlerExt.getLastURL();
    };



    NavigationComponent.prototype.getEntryPointURL = function getEntryPointURL() {
        return this.UrlHandlerExt.getEntryPointURL();
    };
    /**
     * tries to navigate to the group content of the given control
     * - only possible, if control is assigned to a room or category
     * @param control
     * @returns {Promise}
     */


    NavigationComponent.prototype.showGroupContentForControl = function showGroupContentForControl(control) {
        var structureManager = ActiveMSComponent.getStructureManager(),
            room = control && structureManager.getGroupByUUID(control.room),
            cat = control && structureManager.getGroupByUUID(control.cat),
            group = room || cat,
            inAmbient = AMBIENT_MODE;

        if (group) {
            var groupPromise;

            if (group.groupType === GroupTypes.ROOM) {
                groupPromise = this._showActiveMiniserverState(ScreenState.Rooms, {showInAmbientMode: inAmbient});
            } else {
                groupPromise = this._showActiveMiniserverState(ScreenState.Categories, {showInAmbientMode: inAmbient});
            }

            return groupPromise.then(function () {
                return this.showGroupContent(group.groupType, group.uuid, control.uuidAction, true);
            }.bind(this));
        } else {
            return this._showActiveMiniserverState(ScreenState.FavoritesV2, {showInAmbientMode: inAmbient});
        }
    };
    /**
     * navigates to the control, shows group (or HomeScreen) before!
     * @param controlUUID
     * @param urlComps
     */


    NavigationComponent.prototype.navigateToControl = function navigateToControl(controlUUID, urlComps) {
        Debug.GUI.NavigationComp && console.log(this.name, "navigateToControl:", controlUUID);

        if (this._navigateToControlMap[controlUUID]) {
            Debug.GUI.NavigationComp && console.log(this.name, " - skip, already in progress");
            return this._navigateToControlMap[controlUUID];
        }

        var def = Q.defer(),
            control = ActiveMSComponent.getControlByUUID(controlUUID);

        if (control) {
            if (this.getURL().indexOf(controlUUID) !== -1) {
                Debug.GUI.NavigationComp && console.log(this.name, " - we already show this control, do nothing!");
                def.resolve("already showing control!");
            } else {
                Debug.GUI.NavigationComp && console.log(this.name, "    --> ControlContent!");
                def.resolve(this.showGroupContentForControl(control).then(function () {
                    // navigate to group if available, otherwise fav tab
                    if (typeof control.getReactControlContent() === "function") {
                        let controlContentScreenState = AMBIENT_MODE ? "ControlContent" : control.controlType + "ControlContent";
                        return this.showState(controlContentScreenState, {
                            controlUUID: control.uuidAction,
                            urlComps: urlComps,
                            showInAmbientMode: AMBIENT_MODE
                        });
                    } else {
                        let controlContentScreenState = AMBIENT_MODE ? AmbientUtils.getAmbientControlContentName(control) : control.getControlContentViewControllerIdentifier();
                        return Q(control.prepareDataForContentScreen()).then(() => {
                            return this._showActiveMiniserverState(controlContentScreenState, {
                                controlUUID: controlUUID,
                                urlComps: urlComps,
                                showInAmbientMode: AMBIENT_MODE
                            });
                        })
                    }

                }.bind(this))); // handling to prevent duplicate control alerts/contents

                this._navigateToControlMap[controlUUID] = def.promise; // Use Finally, the user can abort the navigation
                // E.g: User Opens Intercom, the secureDetails are loaded, the user cancels the loading and the navigation is aborted

                def.promise.finally(function () {
                    delete this._navigateToControlMap[controlUUID];
                }.bind(this));
            }
        } else {
            Debug.GUI.NavigationComp && console.log(this.name, " - no control found for uuid: " + controlUUID);
            def.reject("no control found for uuid: " + controlUUID);
        }

        return def.promise;
    };
    /**
     *
     * @param control       the control of which the content is to be shown.
     * @param detail        optionally some arguments may be provided using this object.
     * @param animationType the animationType of the ControlContent
     * @param flags
     */


    NavigationComponent.prototype.showControlContent = function showControlContent(control, detail, animationType, flags = {}) {
        let {
            isInAmbientMode = null,
            isAlert = false,
            push = true,
            fromNotification = false,
            initRouteName = null,
        } = flags;
        Debug.GUI.NavigationComp && console.log(this.name, "showControlContent:", control.uuidAction);
        let useAmbientModeCC = AMBIENT_MODE;
        if (typeof isInAmbientMode === "boolean" && isInAmbientMode !== AMBIENT_MODE) {
            useAmbientModeCC = isInAmbientMode;
        }

        let lockingMessage = control.getLockingSystemStatusMessage();
        if (!control.isConfigured()) {
            return NavigationComp.showPopup({
                title: _("unconfigured.title"),
                message: _("unconfigured.message", {
                    name: control.getName()
                }),
                icon: Icon.WARNING,
                color: window.Styles.colors.orange,
                buttonOk: true
            });

        } else if (lockingMessage) {
            App.navigationRef.navigate(ScreenState.MessageCenterMessageScreen, {
                entry: lockingMessage
            });
            return Q.resolve();

        } else if (typeof control.getReactControlContent() === "function") {
            if (!push && useAmbientModeCC) {
                this.removeLastControlContentRouteInAmbient()
            }
            const controlContent = (!isAlert && (useAmbientModeCC)) ? "ControlContent" : control.controlType + "ControlContent";
            this.showState(controlContent, {
                controlUUID: control.uuidAction,
                push: !!push,
                isAlert,
                fromNotification,
                showInAmbientMode: useAmbientModeCC,
                initRouteName,
                contentDetails: detail
            });
        } else {
            let controlContent = useAmbientModeCC && !isAlert ? AmbientUtils.getAmbientControlContentName(control, isAlert) : control.getControlContentViewControllerIdentifier();

            if (!push && useAmbientModeCC) {
                this.removeLastControlContentRouteInAmbient(true)
            }

            return this.showWaitingFor(control.prepareDataForContentScreen()).then(() => {
                return this._handleNavigateToControl(control.uuidAction, controlContent, {
                    controlUUID: control.uuidAction,
                    contentDetails: {
                        ...detail,
                        isAlert,
                        fromNotification
                    },
                    showInAmbientMode: useAmbientModeCC,
                    isAlert,
                    push: !!push,
                    initRouteName,
                }, animationType);
            });
        }
    };

    NavigationComponent.prototype.removeLastControlContentRouteInAmbient = function (includeReactContents) {
        let ambientNavigation = SandboxComponent.getAmbientNavigation();
        if (ambientNavigation) {
            const navigationState = ambientNavigation.getState();
            const stackNavRoute = getRouteFromNavigationState(navigationState, AmbientStackNavigator.name);
            if (stackNavRoute) {
                const ambientStackNavState = stackNavRoute.state;
                if (!ambientStackNavState) {
                    return;
                }

                let controlContentIsShown = false;
                const statesToRemove = [
                    ScreenState.ControlContent,
                    ScreenState.AmbientControlContent,
                    ScreenState.AmbientAudioZoneControlContent,
                    ScreenState.AmbientAudioZoneV2ControlContent,
                ]

                includeReactContents &&  statesToRemove.push("ControlContent")

                let currentAmbientRoute = ambientStackNavState.routes[ambientStackNavState.index]

                if (statesToRemove.includes(currentAmbientRoute.name)) {
                    controlContentIsShown = true
                }

                if (controlContentIsShown) {
                    while (statesToRemove.includes(currentAmbientRoute.name)) {
                        ambientStackNavState.routes.splice(ambientStackNavState.index, 1);
                        ambientStackNavState.index--
                        currentAmbientRoute = ambientStackNavState.routes[ambientStackNavState.index];
                    }

                    ambientNavigation.reset(navigationState);
                }
            }
        }
    }

    /**
     * navigates to the control alert, shows group (or HomeScreen) before!
     * @param controlUUID
     * @param fromNotification If called from userInteraction
     */


    NavigationComponent.prototype.showControlAlert = function showControlAlert(controlUUID, fromNotification) {
        Debug.GUI.NavigationComp && console.log(this.name, "showControlAlert:", controlUUID);

        if (SandboxComponent.isEcoModeActive()) {
            SandboxComponent.toggleEcoModeShown(false);
        }

        if (this._navigateToControlMap[controlUUID]) {
            Debug.GUI.NavigationComp && console.log(this.name, " - skip, already in progress");
            return this._navigateToControlMap[controlUUID];
        }

        var def = Q.defer(),
            control = ActiveMSComponent.getControlByUUID(controlUUID);

        if (control) {
            if (this.getURL().indexOf(controlUUID) !== -1) {
                Debug.GUI.NavigationComp && console.log(this.name, " - we already show this control, do nothing!");
                def.resolve("already showing control!");
            } else {
                def.resolve(this.showControlContent(control, null, null, {
                    isAlert: true,
                    fromNotification
                }));
                //this._navigateToControlMap[controlUUID] = def.promise; // Use Finally, the user can abort the navigation
                // E.g: User Opens Intercom, the secureDetails are loaded, the user cancels the loading and the navigation is aborted

                def.promise.finally(function () {
                    delete this._navigateToControlMap[controlUUID];
                }.bind(this));
            }
        } else {
            def.reject("no control found for uuid: " + controlUUID);
        }

        return def.promise;
    };
    /**
     * Shows the Control Notes as provided in the Config/ ExpertMode
     * @param control
     * @returns {*}
     */


    NavigationComponent.prototype.showControlNotes = function showControlNotes(control) {
        return this._showActiveMiniserverState(ScreenState.ControlHelp, {
            control: control
        });
    };
    /**
     * starts the ExpertMode
     * @param control
     * @param useLightMode if true, the "light" UI will be used
     * @returns {*}
     */


    NavigationComponent.prototype.showControlSettings = function showControlSettings(control, useLightMode) {
        control = control.parentControl || control;
        VendorHub.Usage.expertMode(FeatureUsage.ExpertMode.OPEN, FeatureUsage.ExpertMode.Type.CONTROL);
        return this._showActiveMiniserverState(ScreenState.ControlSettingsController, {
            object: control,
            objectUUID: control.uuidAction,
            objectName: control.name,
            permission: useLightMode ? MsPermission.EXPERT_MODE_LIGHT : MsPermission.EXPERT_MODE
        });
    };
    /**
     * starts the ExpertMode
     * @param group
     * @param useLightMode if true, the "light" UI will be used
     * @returns {*}
     */


    NavigationComponent.prototype.showGroupSettings = function showGroupSettings(group, useLightMode, specialType) {
        VendorHub.Usage.expertMode(FeatureUsage.ExpertMode.OPEN, FeatureUsage.ExpertMode.Type.GROUP);
        return this._showActiveMiniserverState(ScreenState.ControlSettingsController, {
            object: group,
            objectUUID: group.uuid,
            objectName: group.name,
            permission: useLightMode ? MsPermission.EXPERT_MODE_LIGHT : MsPermission.EXPERT_MODE,
            specialType: specialType
        });
    };
    /**
     * shows the device specific settings (rating and isFavorite) to edit
     * @param groupOrControl Either the group or control object
     * @returns {*}
     */


    NavigationComponent.prototype.showDeviceSpecificSettings = function showDeviceSpecificSettings(groupOrControl) {
        return this._showActiveMiniserverState(ScreenState.ControlPerDeviceFavoritesController, {
            object: groupOrControl
        });
    };

    NavigationComponent.prototype.showHome = function showHome() {
        return this._showActiveMiniserverState(ScreenState.Home);
    };

    NavigationComponent.prototype.showMenu = function showMenu() {
        return this._showState(ScreenState.BurgerMenuScreen);
    };

    NavigationComponent.prototype.setNavigateToLastLocation = function setNavigateToLastLocation() {
        this._navigateToLastPosition = true;
    }; // Static Screens
    // -> don't appear in the history, and places on top of all other screens, can't be removed automatically (eg. due to reconnect-waiting)


    NavigationComponent.prototype.showServiceMessageScreen = function showServiceMessageScreen(title, message, iconSrc, btnTitle, link) {
        new GUI.ServiceMessageScreen({
            title: title,
            message: message,
            iconSrc: iconSrc,
            btnTitle: btnTitle,
            link: link
        }).show();
    };

    NavigationComponent.prototype.showPairedAppIdentify = function showPairedAppIdentify(identifyArguments) {
        App.navigationRef.navigate(ScreenState.PairedAppIdentify, identifyArguments);
    }

    /**
     * Presents a static eco screen that can be dismissed by simply clicking on it.
     */
    NavigationComponent.prototype.showAmbientMode = function showAmbientMode() {
        let state = App.navigationRef.getState();
        let ambientInRoute = !!getNavigationState(state, AmbientScreen.name);

        if (!ambientInRoute && SandboxComponent.isEcoModeActive()) {
            Debug.AmbientMode && console.log(this.name, "showAmbientMode: not in route, but eco mode is shown, insert!");
            // "insert" the ambientMode underneath the eco screen!
            App.navigationRef.dispatch(state => {
                let newRoutes = [...state.routes];
                newRoutes.splice(newRoutes.length - 1, 0, {name: AmbientScreen.name });
                const resetArg = {
                    ...state,
                    routes: newRoutes,
                    index: newRoutes.length - 1
                }
                Debug.AmbientMode && console.log(this.name, "showAmbientMode: >>> nav state for reset = ", resetArg);
                return CommonActions.reset(resetArg);
            })

        } else if (!ambientInRoute) {
            Debug.AmbientMode && console.log(this.name, "showAmbientMode: show (in route=" + ambientInRoute + ", ecoActive=" + SandboxComponent.isEcoModeActive());
            App.navigationRef.navigate(ScreenState.AmbientScreen);

        } else {
            Debug.AmbientMode && console.log(this.name, "showAmbientMode: already shown!");
        }

        return Q.resolve();
    }


    /**
     * Presents a welcome screen that lets the user decide if he wants to allow the app to run on startup to have quick access to quickActions
     */
    NavigationComponent.prototype.showQuickActionMenuAcceptScreen = function showQuickActionMenuAcceptScreen() {
        if (!this.quickActionMenuAcceptScreen) {
            this.quickActionMenuAcceptScreen = new GUI.QuickActionMenuAcceptScreen();
        }

        this.quickActionMenuAcceptScreen.show();
    };
    /**
     * Presents a static eco screen that can be dismissed by simply clicking on it.
     */


    NavigationComponent.prototype.showEntryPointWelcomeScreen = function showEntryPointWelcomeScreen() {
        if (!this.entryPointWelcomeScreen) {
            this.entryPointWelcomeScreen = new GUI.EntryPointWelcomeScreen();
        }

        this.entryPointWelcomeScreen.show();
    };
    /**
     * Dismisses the static eco screen.
     */


    NavigationComponent.prototype.hideEntryPointWelcomeScreen = function hideEntryPointWelcomeScreen() {
        if (this.entryPointWelcomeScreen) {
            this.entryPointWelcomeScreen.remove();
        }
    };
    /**
     * shows up the battery monitor
     * @param deviceUuids devices with warnings (eg. low battery)
     * @param mac mac of Miniserver
     * @returns {*}
     */


    NavigationComponent.prototype.showBatteryMonitor = function showBatteryMonitor(deviceUuids, mac) {
        var deviceUuid = deviceUuids && deviceUuids.split(",")[0]; // , separated

        if (typeof mac === "string" && mac !== ActiveMSComponent.getActiveMiniserver().serialNo) {
            // -> url start
            UrlHelper.apply(UrlHelper.createURLStart({
                mac: mac
            }, deviceUuid ? "batterymonitor/" + deviceUuid : "batterymonitor"));
            return;
        }

        var details;

        if (deviceUuid) {
            details = {
                deviceUuid: deviceUuid
            };
        }

        var currentUrl = this.getURL().toLowerCase();

        if (currentUrl.indexOf("batterymonitor/" + deviceUuid) !== -1) {
            // already showing the correct device.. return
            return;
        } else if (currentUrl.indexOf("batterymonitor") !== -1) {
            // already showing the battery monitor.. just update the view, it will navigate..
            return this.getCurrentView().updateView(details);
        }

        return this._showActiveMiniserverState(ScreenState.BatteryMonitor, details, AnimationType.MODAL);
    }; // Popup Stuff


    NavigationComponent.prototype.showPopup = function showPopup() {
        return this.popupManager.showPopup.apply(this.popupManager, arguments);
    };

    NavigationComponent.prototype.removePopup = function removePopup() {
        return this.popupManager.removePopup.apply(this.popupManager, arguments);
    };
    /**
     * Requests the Visualization password form the user
     * @note This function doesn't validate the password!
     * @param [wasWrong] Used for recursive calling!
     * @param [wasWrongBiometric] Used for recursive calling!
     * @param [biometricFailed] Used to show right text when reactivating
     * @returns {*}
     */


    NavigationComponent.prototype.showVisuPasswordPrompt = function showVisuPasswordPrompt(wasWrong, wasWrongBiometric, biometricFailed) {
        var visuPwToken,
            isBiometricVisuPwEnabled = false,
            secretType = BiometricHelper.SecretType.VisuPw;

        if (BiometricHelper.hasEnrolledBiometrics) {
            visuPwToken = PersistenceComponent.getBiometricTokenForSecretType(secretType);
            isBiometricVisuPwEnabled = PersistenceComponent.isBiometricSecretWithTypeEnabled(secretType);
        }

        if (visuPwToken) {
            if (wasWrong) {
                if (BiometricHelper.hasEnrolledBiometrics) {
                    PersistenceComponent.setBiometricTokenForSecretType(null, secretType); // Note: The previous call will disable the biometric visu pw feature, so re-enable it if appropriate

                    PersistenceComponent.setBiometricSecretWithSecretTypeEnabled(secretType, isBiometricVisuPwEnabled);
                }

                return this.showVisuPasswordPrompt(wasWrong, true, biometricFailed);
            } else {
                return BiometricHelper.getSecretOfType(secretType).then(function (res) {
                    return {
                        result: res
                    };
                }, function (e) {
                    if (e === GUI.PopupBase.ButtonType.CANCEL) {
                        // The user canceled the authentication request, throw the cancel button, just like a real Popup...
                        throw GUI.PopupBase.ButtonType.CANCEL;
                    } else {
                        if (e === BiometricHelper.ErrorCodes.SecretNotFound) {
                            biometricFailed = true;
                        }

                        return this.showVisuPasswordPrompt(true, false, biometricFailed);
                    }
                }.bind(this));
            }
        } else {
            return this.showPopup(this._getVisuPwPopupContent(wasWrong, wasWrongBiometric, isBiometricVisuPwEnabled, biometricFailed), PopupType.INPUT, true);
        }
    };


    /**
     * Asks the user for the current password, which will then be verified. If valid - it will resolve with the password
     * the user has just entered. If canceled, it will reject the promise.
     * @param title
     * @param okButton
     * @param message
     * @param [wasWrong] Used for recursive calling!
     * @param [wasWrongBiometric] Used for recursive calling!
     * @param [biometricFailed] Used to show right text when reactivating
     * @param [noVerify] Just request it, don't verify it with the Miniserver (except for biometric-auth-persistance)
     * @returns {*}
     */
    NavigationComponent.prototype.requestPasswordFromCurrentUser = function requestPasswordFromCurrentUser(title, okButton, message, wasWrong, wasWrongBiometric, biometricFailed, noVerify = false) {
        Debug.Tokens && console.log(this.name, "requestPasswordFromCurrentUser: " + title + ", " + message);
        return this.showWaitingFor(ActiveMSComponent.comReadyPrms).then(function () {
            var prms,
                currentCreds = ActiveMSComponent.getCurrentCredentials(),
                currentUser = currentCreds.username,
                userPwToken,
                isBiometricUserPwEnabled = false,
                secretType = BiometricHelper.SecretType.UserPw;
            title = title || _('password-confirmation', {
                user: currentUser
            });
            okButton = okButton || _('finish');

            if (BiometricHelper.hasEnrolledBiometrics) {
                userPwToken = PersistenceComponent.getBiometricTokenForSecretType(secretType);
                isBiometricUserPwEnabled = PersistenceComponent.isBiometricSecretWithTypeEnabled(secretType);
            }

            if (userPwToken) {
                if (wasWrong) {
                    if (BiometricHelper.hasEnrolledBiometrics) {
                        PersistenceComponent.setBiometricTokenForSecretType(null, secretType); // Note: The previous call will disable the biometric user pw feature, so re-enable it if appropriate

                        PersistenceComponent.setBiometricSecretWithSecretTypeEnabled(secretType, isBiometricUserPwEnabled);
                    }

                    return this.requestPasswordFromCurrentUser(title, okButton, message, wasWrong, true, biometricFailed, noVerify);
                } else {
                    prms = BiometricHelper.getSecretOfType(secretType).then(function (secret) {
                        // Fake the response from an input popup
                        return {
                            result: secret
                        };
                    }, function (e) {
                        if (e === GUI.PopupBase.ButtonType.CANCEL) {
                            // The user canceled the authentication request, throw the cancel button, just like a real Popup...
                            throw GUI.PopupBase.ButtonType.CANCEL;
                        } else {
                            if (e === BiometricHelper.ErrorCodes.SecretNotFound) {
                                biometricFailed = true;
                            }

                            return this.requestPasswordFromCurrentUser(title, okButton, message, true, false, biometricFailed, noVerify);
                        }
                    }.bind(this));
                }
            } else {
                // store a reference to the request password popup, as it should be dismissed as soon as a stopMsSession is received.
                prms = this.showPopup(this._getUserPwPopupContent(title, message, okButton, wasWrong, wasWrongBiometric, isBiometricUserPwEnabled, biometricFailed), PopupType.INPUT, true);
            }

            return prms.then(function (popupRes) {
                // fallback for activation popup
                if (typeof popupRes === "string") {
                    popupRes = {
                        result: popupRes
                    };
                }

                // if the password will be stored it always must be verified, otherwise only verify if not contradicted by the flag.
                let willBeStored = BiometricHelper.hasEnrolledBiometrics && !PersistenceComponent.getBiometricTokenForSecretType(secretType),
                    verifyPrms;
                if (!willBeStored && noVerify) {
                    Debug.Tokens && console.log(this.name, "requestPasswordFromCurrentUser: password will not be verified, no bio auth store update & noVerify flag set!");
                    verifyPrms = Q.resolve();
                } else {
                    Debug.Tokens && console.log(this.name, "requestPasswordFromCurrentUser: password will be verified, " + (willBeStored ? "biometric store update" : "no biometric store update") + " & noVerify flag " + (noVerify ? "NOT" : "is" ) + " set!");
                    verifyPrms = ActiveMSComponent.verifyPassword(popupRes.result);
                }

                return verifyPrms.then(function () {
                    if (BiometricHelper.hasEnrolledBiometrics && !PersistenceComponent.getBiometricTokenForSecretType(secretType)) {
                        return BiometricHelper.setSecretOfType(popupRes.result, secretType).then(function () {
                            return popupRes.result;
                        });
                    } else {
                        return popupRes.result;
                    }
                }.bind(this), function (err) {
                    console.error("Password didn't match " + JSON.stringify(err));
                    return this.requestPasswordFromCurrentUser(title, okButton, message, true, isBiometricUserPwEnabled, noVerify);
                }.bind(this));
            }.bind(this));
        }.bind(this));
    };

    NavigationComponent.prototype.askForDisconnection = function askForDisconnection() {
        var content = {
            title: _('miniserverlist.disconnect.confirmation'),
            buttonOk: _('disconnect'),
            buttonCancel: true,
            icon: Icon.INFO,
            color: window.Styles.colors.orange
        };
        return this.showPopup(content).then(function () {
            this.showArchive();
            return "user requested disconnection";
        }.bind(this), function () {
            throw new Error("user canceled disconnection!");
        });
    };
    /**
     * disconnects from the Miniserver and shows archive (or login form in WI)
     */


    NavigationComponent.prototype.disconnect = function disconnect(switchUserOnly) {
        var msInfo,
            reachMode = CommunicationComponent.getCurrentReachMode(); // retrieve from persistence component. the object stored in active ms may not have all data ready.

        msInfo = PersistenceComponent.getMiniserver(ActiveMSComponent.getActiveMiniserver().serialNo); // disconnect from miniserver (emits StopMSSession)
        // always reset url after a disconnect from the menu

        SandboxComponent.activityTick()
        ActiveMSComponent.disconnectMiniserver(true); // decide what to do/show next

        if (switchUserOnly || PlatformComponent.getPlatformInfoObj().platform === PlatformType.Webinterface) {
            // only pass the important information
            var credsMiniserver = {
                localUrl: msInfo.localUrl,
                remoteUrl: msInfo.remoteUrl,
                msName: msInfo.msName,
                serialNo: msInfo.serialNo,
            };
            var credsViewParams = {
                state: LoginState.WAITING_FOR_CREDS,
                miniserver: credsMiniserver,
                reachMode: reachMode
            };
            App.navigationRef.reset(App.DisconnectedState(ScreenState.Credentials, credsViewParams))
        } else {
            this.showArchive();
        }
    };

    NavigationComponent.prototype.showSupportErrorPopup = function showSupportErrorPopup(code) {
        var def = Q.defer();
        var message = SupportCode.getSupportMessage(code);

        if (message) {
            var content = {
                title: message.title,
                message: message.text,
                buttonCancel: _('okay'),
                icon: Icon.CAUTION,
                color: window.Styles.colors.orange
            };

            if (message.showContactSupportButton) {
                content.buttonOk = _('contact-support');
            }

            this.showPopup(content).then(function () {
                // go to email!
                var link = "mailto:support@loxone.com?subject=" + encodeURIComponent("Issue with Loxone App (" + PlatformComponent.getAppInfoObj().appVersion + ")");
                link = link + "&body=" + encodeURIComponent("\nDescribe error:\n...\n\nMessage:\n\"" + message + "\"");
                this.openWebsite(link);
                def.resolve();
            }.bind(this), function () {
                def.resolve();
            });
        } else {
            def.reject(); // reject, when no popup is shown!
        }

        return def.promise;
    };
    /**
     * displays an "general-error" popup
     * @param navigateBackOnOk
     * @param customTitle
     * @param customMessage
     */


    NavigationComponent.prototype.showErrorPopup = function showErrorPopup(navigateBackOnOk, customTitle, customMessage) {
        var content = {
            title: customTitle || _('error'),
            message: customMessage || _('error.occured'),
            buttonOk: true,
            icon: Icon.CAUTION,
            color: window.Styles.colors.red
        };
        this.showPopup(content).done(function () {
            if (navigateBackOnOk) {
                NavigationComp.navigateBack();
            }
        });
    };
    /**
     * Displays a new selector screen that allows to pick from a given set of options.
     * @param details
     * @returns {*}     a promise that will resolve with the pick or reject if nothing was picked.
     */


    NavigationComponent.prototype.showSelector = function showSelector(details) {
        details.deferred = Q.defer();
        this.showState(ScreenState.SelectorScreen, details);
        return details.deferred.promise;
    };

    NavigationComponent.prototype.showUnderConstructionPopup = function showUnderConstructionPopup() {
        var content = {
            title: _("under-construction.title"),
            message: _("under-construction.message"),
            buttonOk: true,
            icon: Icon.INFO,
            color: window.Styles.colors.orange
        };
        this.showPopup(content);
    };

    NavigationComponent.prototype.showCurrentlyNotSupportedPopup = function showCurrentlyNotSupportedPopup(text) {
        var content = {
            title: text,
            buttonOk: true,
            icon: Icon.CAUTION
        };
        this.showPopup(content);
    }; // Animations


    NavigationComponent.prototype.animationsEnabled = function animationsEnabled() {
        return this.NavigationExt.animationsEnabled();
    };

    NavigationComponent.prototype.enableAnimations = function enableAnimations(enabled) {
        this.NavigationExt.enableAnimations(enabled);
        PersistenceComponent.setAnimationState(enabled);
    };

    NavigationComponent.prototype.disableAnimationsTemp = function disableAnimationsTemp() {
        this.NavigationExt.disableAnimationsTemp();
    };

    NavigationComponent.prototype.tileRepresentationEnabled = function tileRepresentationEnabled() {
        return PersistenceComponent.getTileRepresentationState();
    };

    NavigationComponent.prototype.enabledTileRepresentation = function enabledTileRepresentation(enabled) {
        PersistenceComponent.setTileRepresentationState(enabled);
        this.dispatchEventToUI(NavigationComp.UiEvents.TilePresentationChanged);
    }; // VC Stuff


    NavigationComponent.prototype.getCurrentView = function getCurrentView() {
        return this.NavigationExt.getCurrentView.call(this.NavigationExt);
    };

    NavigationComponent.prototype.updateURL = function updateURL(url = this.getURL()) {
        this.UrlHandlerExt.updateURL(url);
        this.dispatchEventToUI(NavigationComp.UiEvents.UrlUpdate, url);
    };

    NavigationComponent.prototype.syncEntryPointURL = function syncEntryPointURL(url) {
        this.UrlHandlerExt.syncEntryPointURL();
    };

    NavigationComponent.prototype.getURL = function getURL() {
        return this.NavigationExt.getURL() || window.CURRENT_URL || "";
    };

    NavigationComponent.prototype.reloadWithCurrentUrl = function reloadWithCurrentUrl() {
        var miniserver = ActiveMSComponent.getActiveMiniserver();
        this.UrlHandlerExt.reloadWithCurrentUrl(miniserver);
    };

    NavigationComponent.prototype.getCurrentActivityType = function getCurrentActivityType() {
        return this._currentActivityType;
    }; // Activities


    NavigationComponent.prototype.startActivity = function startActivity(activityType, type, details) {
        // TODO-thallth suppress room mode!
        this._currentActivityType = activityType;
        this.NavigationExt.startActivity.call(this.NavigationExt, activityType, type, details);
    };

    NavigationComponent.prototype.stopActivity = function stopActivity(activityType) {
        this._currentActivityType = null;
        this.NavigationExt.stopActivity.call(this.NavigationExt, activityType);
    }; // Notifications

    /**
     * toggles all Notifications
     * @param show {boolean} if notifications should be shown or not
     */


    NavigationComponent.prototype.toggleNotifications = function toggleNotifications(show) {
        this.notificationManager.toggleNotifications(show);
    };

    NavigationComponent.prototype.toggleNotification = function toggleNotification(notification, hidden) {
        this.notificationManager.toggleNotification(notification, hidden);
    };
    /**
     * tells the notificationManager to show the notification
     * @param notification {Notification}
     * @param type {NotificationType}
     */


    NavigationComponent.prototype.showNotification = function showNotification(notification, type) {
        this.notificationManager.showNotification(notification, type);
    };
    /**
     * tells the notificationManager to remove the notification
     * @param notification
     * @param animated
     */


    NavigationComponent.prototype.removeNotification = function removeNotification(notification, animated) {
        this.notificationManager.removeNotification(notification, animated);
    };
    /**
     * handles the location url and navigates to the correct position
     * @param url
     * @returns {Promise|null}
     */


    NavigationComponent.prototype.handleURL = function handleURL(url) {
        // TODO-goelzda: M9-T132 Restructure to make it more readable
        Debug.GUI.NavigationComp && console.info(this.name, "NavigationComponent handleURL: " + url);

        if (this._waitingForConnection) {
            Debug.GUI.NavigationComp && console.info(this.name, " - don't handle the URL immediately, wait until the connection was established!");
            this._delayedHandleURL = url;
            return;
        }

        var showScreen = function (subScreen, subScreenDetails) {
            if (!subScreen) {
                subScreen = ScreenState.FavoritesV2;
            }

            return this._showActiveMiniserverState(subScreen, subScreenDetails);
        }.bind(this);

        var showControlExpertMode = function (controlUUID) {
            var control = ActiveMSComponent.getControlByUUID(controlUUID);

            if (control) {
                return this.showControlSettings(control);
            }
        }.bind(this);

        var navigateToGroup = function (groupType, groupUUID) {
            //console.log("navigateToGroup: " + groupType + " " + groupUUID);
            var promise = showScreen(groupType === GroupTypes.ROOM ? ScreenState.Rooms : ScreenState.Categories); // check first, if the groupUUID is given and if we have content in it to show

            if (groupUUID && structureManager.getControlsInGroup(groupType, groupUUID).length > 0) {
                promise.then(function () {
                    return this.showGroupContent(groupType, groupUUID, false, true);
                }.bind(this));
            }

            return promise;
        }.bind(this);

        var structureManager = ActiveMSComponent.getStructureManager();

        if (url && url[0]) {
            //console.log("url[0] = " + url[0]);
            // ONLY CHECK lowercase!
            url[0] = url[0].toLowerCase();

            if (url[0] === UrlStartLocation.CENTRAL || url[0] === UrlStartLocation.HOUSE || // The old "House" Tab is out new "Central" Tab
                url[0] === UrlStartLocation.HOME_LEGACY) {
                // The old "Home" tab is our new "House" Tab
                return showScreen(ScreenState.House);
            } else if (url[0] === UrlStartLocation.FAVORITES) {
                return showScreen(ScreenState.FavoritesV2);
            } else if (url[0] === GroupTypes.ROOM || url[0] === GroupTypes.CATEGORY) {
                var groupType = url[0],
                    groupUUID = url[1];
                if (this.getURL().indexOf(groupUUID) !== -1 && !this._navigateToLastPosition) {
                    //console.info("already showing group..");
                    return;
                }

                return navigateToGroup(groupType, groupUUID);
            } else if (url[0] === UrlStartLocation.CONTROL || url[0] === UrlStartLocation.CONTROL_ALERT) {
                // ControlContent (="Control/<controlUUID>/<SubScreenName>/<SubScreenName>/"
                var controlUUID = url[1];

                if (this.getURL().indexOf(controlUUID) !== -1 && !this._navigateToLastPosition) {
                    console.info("already showing control..");
                    let currentView = this.getCurrentView();

                    if (url[2] === "expertMode") {
                        showControlExpertMode(controlUUID);
                    } else if (currentView instanceof GUI.ControlContentViewController) {
                        // Just update the view and pass the urlComps, so the control can handle them
                        currentView.fromNotification = true;
                        currentView.updateView({
                            urlComps: url.slice(2, url.length)
                        });
                    }

                    return;
                }

                if (url[0] === UrlStartLocation.CONTROL_ALERT) {
                    // Navigating to the Controls Group takes too long, that's why we directly navigate to the FavScreen
                    // and then show the controlAlert
                    // showControlAlert will also be called from the stateContainer of the triggered control
                    // We won't show multiple controlAlerts, it is handled in the showControlAlert function
                    return showScreen().then(this.showControlAlert.bind(this, controlUUID, true));
                } else {
                    return this.navigateToControl(controlUUID, url.slice(2, url.length)).then(function () {
                        if (url[2] === "expertMode") {
                            showControlExpertMode(controlUUID);
                        }
                    }.bind(this)).fail(function () {
                        EntryPointHelper.resetEntryPointLocation();
                        showScreen();
                    });
                }
            } else if (url[0] === UrlStartLocation.TASK_RECORDER) {
                return showScreen().then(function () {
                    if (!Feature.NO_TASK_RECORDER) {
                        return this._showActiveMiniserverState(ScreenState.TaskRecorder);
                    }
                }.bind(this));
            } else if (url[0] === UrlStartLocation.WEATHER) {
                return showScreen().then(function () {
                    return this._showActiveMiniserverState(ScreenState.Weather);
                }.bind(this));
            } else if (url[0] === UrlStartLocation.QUICK_ACTIONS) {
                return showScreen().then(function () {
                    return this._showActiveMiniserverState(ScreenState.QuickActions);
                }.bind(this));
            } else if (url[0] === UrlStartLocation.PRESENCE_DETECTOR) {
                // Loxone Control
                return showScreen().then(function () {
                    return this._showActiveMiniserverState(ScreenState.PresenceDetector);
                }.bind(this));
            } else if (url[0] === UrlStartLocation.AUTO_PILOT || url[0] === UrlStartLocation.AUTOMATIC_DESIGNER) {
                return showScreen().then(function () {
                    if (SandboxComponent.isAutopilotAvailable()) {
                        if (Feature.AUTOMATIC_DESIGNER) {
                            var details = null;

                            if (url[1]) {
                                details = {
                                    ruleUuid: url[1]
                                };
                            }

                            return this._showActiveMiniserverState(ScreenState.AutomaticDesigner.OverviewScreen, details);
                        } else {
                            return this._showActiveMiniserverState(ScreenState.Autopilot.MainScreen);
                        }
                    }
                }.bind(this));
            } else if (url[0] === UrlStartLocation.AUTOMATIC_DESIGNER_SCENES) {
                return showScreen().then(function () {
                    if (SandboxComponent.isAutopilotAvailable() && Feature.AUTOMATIC_DESIGNER_SCENES) {
                        var details = null;

                        if (url[1]) {
                            details = {
                                sceneUuid: url[1],
                                extension: AutomaticDesignerEnums.RULE_EXTENSION.SCENE
                            };
                        }

                        return this._showActiveMiniserverState(ScreenState.AutomaticDesigner.ScenesOverviewScreen, details);
                    }
                }.bind(this));
            } else if (url[0] === UrlStartLocation.BATTERY_MONITOR) {
                return showScreen().then(function () {
                    return this.showBatteryMonitor(url[1], url[2] || null);
                }.bind(this));
            } else if (url[0] === UrlStartLocation.PASSWORD_CHANGE) {
                if (ActiveMSComponent.isUserAllowedToChangePW()) {
                    return showScreen().then(function () {
                        return this._showActiveMiniserverState(ScreenState.ChangeSecret, {
                            user: ActiveMSComponent.getCurrentUser(),
                            requestCurrentPassword: !Feature.TOKENS,
                            type: "userPw"
                        }, AnimationType.MODAL);
                    }.bind(this));
                } else {
                    console.warn("User is not allowed to change the password!");
                    return showScreen();
                }
            } else if (url[0] === UrlStartLocation.USER_MANAGEMENT) {
                if (ActiveMSComponent.getRequiredUserManagementPermissionInfos().hasUserManagementPermissions) {
                    return showScreen().then(function () {
                        return this._showActiveMiniserverState(ScreenState.UserManagementScreen, {
                            userUuid: url[1]
                        });
                    }.bind(this));
                } else {
                    console.warn("User is not allowed to open the UserManagement!");
                    return showScreen();
                }
            } if (url[0] === UrlStartLocation.DEVICE_SEARCH) {
                if (SandboxComponent.checkPermission(MsPermission.DEVICE_MANAGEMENT)) {
                    return showScreen().then(function () {
                        return this._showActiveMiniserverState(ScreenState.DeviceSearchViewController, {
                            searchType: url[1]
                        });
                    }.bind(this));
                } else {
                    console.warn("User is not allowed to open the device search!");
                    return showScreen();
                }
            } else if (url[0] === UrlStartLocation.PARTNER_INFO) {
                if (ActiveMSComponent.getPartnerDetails()) {
                    return showScreen().then(function () {
                        return this._showActiveMiniserverState(ScreenState.PartnerBranding);
                    }.bind(this));
                } else {
                    console.warn("Can't show PartnerInfo screen with no partner info data!");
                    return showScreen();
                }
            } else if (url[0] === UrlStartLocation.MESSAGE_CENTER) {
                return showScreen().then(function () {
                    // Check if we have a messageCenter entryUUID and open it directly
                    if (url[1]) {
                        return this._showActiveMiniserverState(ScreenState.MessageCenterMessagesScreen, {
                            entryUuid: url[1]
                        });
                    } else {
                        return this._showActiveMiniserverState(ScreenState.MessageCenterMessagesScreen, {
                            forceFetch: true // We can't ensure to have an up to date structure, for example the app is closed and the user taps on a Push Notification. The Structure has to be fetched before we can show any entry

                        });
                    }
                }.bind(this));
            } else if (url[0] === UrlStartLocation.NOTIFICATIONS && SandboxComponent.notificationsAvailableForActiveMiniserver(true)) {
                // Check if the miniserver is capable of handling PushNotifications
                return showScreen().then(function () {
                    var platform = PlatformComponent.getPlatformInfoObj().platform,
                        isPushNotificationSupported = (platform === PlatformType.Android || platform === PlatformType.IOS) && pushNotificationService.deviceSupportsPushNotifications; // Check if we have the "subnotification type" (In-App or Push)

                    if (url[1]) {
                        if (url[1] === "push" && isPushNotificationSupported) {
                            return this._showActiveMiniserverState(ScreenState.PushNotificationsSettings);
                        } else if (url[1] === "inapp") {
                            return this._showActiveMiniserverState(ScreenState.InAppNotificationsSettings);
                        }
                    }

                    return this._showActiveMiniserverState(ScreenState.NotificationsSettings);
                }.bind(this));
            } else {
                // default..
                return showScreen();
            }
        } else {
            //console.log("no url");
            return showScreen();
        }
    };

    NavigationComponent.prototype._showState = function _showState(state, details, animationType) {
        return this.NavigationExt.showState.call(this.NavigationExt, state, details, animationType);
    };
    /**
     * this states MUST be only shown when a connection is active
     * if we currently try to connect, we wait..
     * @param state
     * @param details
     * @param animationType
     * @returns {*}
     * @private
     */


    NavigationComponent.prototype._showActiveMiniserverState = function _showActiveMiniserverState(state, details, animationType) {
        if (this._locationQueue.length === 0 && // if we have nothing in the queue
            !this._waitingForConnection) {
            // and if we should NOT _waitingForConnection (means that the connection is established and no waiting etc. is shown)
            // we have nothing in the queue, go on!
            return this._showState.apply(this, arguments);
        } else {
            Debug.GUI.NavigationComp && console.log(this.name, "_showActiveMiniserverState put to queue..");
            var def = Q.defer(); // we have some states in the queue (which is also the case when the connection closes/reachMode changes)
            // put this state also to the queue and wait until the connection is ready -> queue will be processed!

            this._locationQueue.push({
                args: arguments,
                def: def
            });

            return def.promise;
        }
    };
    /**
     * processes the ActiveMiniserver queue
     * @private
     */


    NavigationComponent.prototype._processActiveMiniserverStateQueue = function () {
        var queueObj = this._locationQueue.shift();

        if (!queueObj) return;

        var processNext = this._processActiveMiniserverStateQueue.bind(this);

        queueObj.def.promise.then(processNext, processNext);
        queueObj.def.resolve(this._showState.apply(this, queueObj.args));
    };
    /**
     * cleans the queue because of a connection attempt to another Miniserver
     * @private
     */


    NavigationComponent.prototype._cleanActiveMiniserverStateQueue = function () {
        while (this._locationQueue.length > 0) {
            Debug.GUI.NavigationComp && console.info(this.name, " - rejecting activeMS Queue object!");

            this._locationQueue.shift().def.reject("Reject due to switching Miniservers");
        }
    };

    NavigationComponent.prototype.showInitialView = function showInitialView(pairingInfo) {
        if (PlatformComponent.getPlatformInfoObj().platform !== PlatformType.Webinterface) {
            var q = Q.defer(),
                promise = q.promise;

            if (pairingInfo) {
                this.connectTo(PairedAppComponent.getPairedAppMiniserver());
                q.resolve();

            } else if (!Object.keys(PersistenceComponent.getAllMiniserver()).length) {
                if (PlatformComponent.getNetworkStatus().status === NetworkStatus.LAN && MiniserverFinder.hasMSFinder()) {
                    var platform = PlatformComponent.getPlatformInfoObj().platform;

                    if (platform === PlatformType.IOS) {
                        promise = this.showState(ScreenState.Welcome);
                    } else {
                        promise = this.showState(ScreenState.InitialSearch);
                    }
                } else {
                    promise = this.showState(ScreenState.Welcome);
                }
            } else {
                var lastURLMS = PersistenceComponent.getLastConnectedMiniserver();

                if (PersistenceComponent.backupAndSyncGetLastConnectedDeleted()) {
                    promise = NavigationComp.showPopup({
                        title: _("backup-and-sync.popup.not-found.title"),
                        message: _("backup-and-sync.popup.not-found.message", {
                            name: lastURLMS.msName
                        }),
                        buttonOk: true
                    }).then(function () {
                        return this.showArchive();
                    }.bind(this));
                } else if (lastURLMS) {
                    this.connectTo(lastURLMS);
                    q.resolve();
                } else {
                    // we were at another place before, stay in archive!
                    promise = this.showArchive();
                }
            }

            promise.then(function () {
                if (navigator.splashscreen && PlatformComponent.getPlatformInfoObj().platform !== PlatformType.Mac) {
                    // TODO-goelzda remove timeout
                    setTimeout(navigator.splashscreen.hide, 200);
                }
            });
        }
    };

    NavigationComponent.prototype._updateTextSizeAdjustment = function _updateTextSizeAdjustment() {
        var textAdjust = Math.min(PlatformComponent.getPlatformInfoObj().accessibility.textSizeAdjustment, MAX_TEXT_SIZE_ADJUST_IN_PERCENT);
        $(document.body)[0].style.setProperty("-webkit-text-size-adjust", textAdjust + "%", "important");
    };
    /**
     * if we are connected to a Miniserver, it disconnects
     * @private
     */


    NavigationComponent.prototype._disconnectFromMsIfNeeded = function _disconnectFromMsIfNeeded() {
        if (ActiveMSComponent.getActiveMiniserver() !== null) {
            Debug.MSSession && console.log(this.name, "_disconnectFromMsIfNeed -> StopMSSession");
            CompChannel.emit(CCEvent.StopMSSession, true);
        }
    };

    NavigationComponent.prototype._handleNavigateToControl = function _handleNavigateToControl(controlUUID, screenState, details, animationType) {
        Debug.GUI.NavigationComp && console.log(this.name, "_handleNavigateToControl:", controlUUID);

        if (this._navigateToControlMap[controlUUID]) {
            Debug.GUI.NavigationComp && console.log(this.name, " - skip, already in progress");
            return this._navigateToControlMap[controlUUID];
        } // handling to prevent duplicate control alerts/contents


        this._navigateToControlMap[controlUUID] = this._showActiveMiniserverState(screenState, details, animationType); // Use Finally, the user can abort the navigation
        // E.g: User Opens Intercom, the secureDetails are loaded, the user cancels the loading and the navigation is aborted

        this._navigateToControlMap[controlUUID].finally(function () {
            delete this._navigateToControlMap[controlUUID];
        }.bind(this));

        return this._navigateToControlMap[controlUUID];
    };

    NavigationComponent.prototype._getUserPwPopupContent = function _getUserPwPopupContent(title, msg, okButton, wasWrong, wasWrongBiometric, isBiometricUserPwEnabled, biometricFailed) {
        var icon,
            color = window.Styles.colors.red,
            biometricTypePhrase = BiometricHelper.getBiometricTypePhrase();

        if (isBiometricUserPwEnabled) {
            icon = BiometricHelper.getBiometricGlyph();

            if (wasWrong) {
                if (biometricFailed) {
                    title = biometricTypePhrase;
                    msg = _("secured.password.request.biometric-explanation.enable", {
                        biometricType: biometricTypePhrase,
                        passwordType: BiometricHelper.getPasswordTypeFromSecretType(BiometricHelper.SecretType.UserPw)
                    });
                } else {
                    title = _("wrong-password");

                    if (wasWrongBiometric) {
                        msg = _("secured.password.request.biometric-explanation.re-enable", {
                            biometricType: biometricTypePhrase,
                            passwordType: BiometricHelper.getPasswordTypeFromSecretType(BiometricHelper.SecretType.UserPw)
                        });
                        color = window.Styles.colors.orange;
                    } else {
                        msg = _("secured.password.request.biometric-explanation.enable", {
                            biometricType: biometricTypePhrase,
                            passwordType: BiometricHelper.getPasswordTypeFromSecretType(BiometricHelper.SecretType.UserPw)
                        });
                    }
                }
            } else {
                title = biometricTypePhrase;
                msg = _("secured.password.request.biometric-explanation.enable", {
                    biometricType: biometricTypePhrase,
                    passwordType: BiometricHelper.getPasswordTypeFromSecretType(BiometricHelper.SecretType.UserPw)
                });
            }
        } else {
            icon = Icon.CAUTION;
            title = title || (wasWrong ? _("wrong-password") : _("password"));
        }

        return {
            title: title,
            message: msg,
            input: {
                id: "password",
                type: GUI.LxInputEnum.Type.PASSWORD,
                required: true,
                placeholder: _("password")
            },
            buttonOk: okButton || _("finish"),
            buttonCancel: true,
            icon: icon,
            color: color
        };
    };
    /**
     * Returns the PopupConfig for the Visualisation popup. The popup will change according to the Biometric ID feature
     * @param wasWrong
     * @param wasWrongBiometric
     * @param isBiometricVisuPwEnabled
     * @param biometricFailed
     * @returns {{input: {id: string, placeholder: *, type: string, required: boolean}, buttonCancel: boolean, color: string, icon: string, buttonOk: *, title: *, message: *}}
     * @private
     */


    NavigationComponent.prototype._getVisuPwPopupContent = function _getVisuPwPopupContent(wasWrong, wasWrongBiometric, isBiometricVisuPwEnabled, biometricFailed) {
        var icon,
            title,
            msg,
            color,
            biometricTypePhrase = BiometricHelper.getBiometricTypePhrase();

        if (isBiometricVisuPwEnabled) {
            icon = BiometricHelper.getBiometricGlyph();

            if (wasWrong) {
                if (biometricFailed) {
                    title = biometricTypePhrase;
                    msg = _("secured.password.request.biometric-explanation.enable", {
                        biometricType: biometricTypePhrase,
                        passwordType: BiometricHelper.getPasswordTypeFromSecretType(BiometricHelper.SecretType.VisuPw)
                    });
                } else {
                    title = _("secured.password.invalid.title");

                    if (wasWrongBiometric) {
                        msg = _("secured.password.request.biometric-explanation.re-enable", {
                            biometricType: biometricTypePhrase,
                            passwordType: BiometricHelper.getPasswordTypeFromSecretType(BiometricHelper.SecretType.VisuPw)
                        });
                    } else {
                        msg = _("secured.password.request.biometric-explanation.enable", {
                            biometricType: biometricTypePhrase,
                            passwordType: BiometricHelper.getPasswordTypeFromSecretType(BiometricHelper.SecretType.VisuPw)
                        });
                    }
                }
            } else {
                title = biometricTypePhrase;
                msg = _("secured.password.request.biometric-explanation.enable", {
                    biometricType: biometricTypePhrase,
                    passwordType: BiometricHelper.getPasswordTypeFromSecretType(BiometricHelper.SecretType.VisuPw)
                });
            }

            color = window.Styles.colors.orange;
        } else if (wasWrong) {
            icon = Icon.CAUTION;
            title = _("secured.password.invalid.title");
            msg = _("secured.password.request.message");
            color = window.Styles.colors.orange;
        } else {
            icon = Icon.INFO;
            title = _("visu-password");
            msg = _("secured.password.request.message");
            color = window.Styles.colors.stateActive;
        }

        return {
            title: title,
            message: msg,
            input: {
                id: "visuPassword",
                type: GUI.LxInputEnum.Type.PASSWORD,
                required: true,
                placeholder: _("visu-password")
            },
            buttonOk: _("finish"),
            buttonCancel: true,
            icon: icon,
            color: color
        };
    };

    Components.Navigation = {
        Init: NavigationComponent,
        extensions: {}
    };
    return Components;
}(window.Components || {});
